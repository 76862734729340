export function nav() {
    return [
        {
            id: 'home',
            icon: 'mdi-home',
            label: 'Home',
            to: { name: 'Dashboard' },
        }, {
            id: 'operators',
            icon: 'mdi-account-multiple',
            label: 'Operators',
            to: { name: 'Operators' },
        }, {
            id: 'subscriptions',
            icon: 'mdi-credit-card',
            label: 'Subscriptions',
            to: { name: 'Subscriptions' },
        }, {
            id: 'purchases',
            icon: 'mdi-bank',
            label: 'Purchases',
            to: { name: 'Purchases' },
        },
    ];
}
