












import Vue from 'vue';

import HeaderComponent from './components/Header.vue';
import SidebarComponent from './components/Sidebar.vue';

export default Vue.extend({
    name: 'LayoutMain',

    components: {
        HeaderComponent,
        SidebarComponent,
    },

    data() {
        return {
            drawer: true,       
        };
    },

    methods: {
        drawerToggle() {
            this.drawer = !this.drawer;
        },
    },
});
