



















import Vue from 'vue';

import { projectName } from '@/consts/config';
import { mapActions } from 'vuex';
import { loginActions } from '@/store/modules/login/names';

export default Vue.extend({
    name: 'HeaderComponent',

    props: {
        drawer: {
            type: Function,
            required: true,
        },
    },

    data() {
        return {
            title: projectName,
        };
    },

    methods: {
        ...mapActions({
            logout: loginActions.logout,
        }),

        async logoutUser() {
            const res = await this.logout();

            if (res) {
                this.$router.push({ name: 'Login' });
            }
        },
    },
});
