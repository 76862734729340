






















import Vue from 'vue';

import { nav } from '@/consts/menu';

export default Vue.extend({
    name: 'SidebarComponent',

    props: {
        drawer: {
            type: Boolean,
            required: true,
        },
    },

    computed: {
        menu() {
            return nav();
        },
    },
});
